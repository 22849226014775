import React from 'react'
import { connect } from 'react-redux'
import Navigation from './navigation'
import { Link } from 'gatsby'
import './header.sass'
import { updateWindowDimensions } from '@helpers/header'

export const Header = ({ isMobile }) => {
  React.useEffect(() => {
    updateWindowDimensions()
    window.addEventListener('resize', updateWindowDimensions)
    return () => window.removeEventListener('resize', updateWindowDimensions)
  }, [])
  return (
    <header className="header">
      <div className="grid-x">
        <div className="small-6">
          <Link to="/" className="home-link">
            <h1>Tyler Drake</h1>
            <span className="slider"></span>
          </Link>
        </div>
        <div className="small-6 nav">
          <Navigation isMobile={isMobile} />
        </div>
      </div>
    </header>
  )
}

const mapStateToProps = state => {
  return { isMobile: state.global.isMobile }
}

export default connect(mapStateToProps)(Header)
