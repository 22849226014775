import { store } from '@redux/store'
import { setIsLandscape, setIsMobile } from '@redux/modules/global'

export const updateWindowDimensions = () => {
  const { isMobile, isLandscape } = store.getState().global
  if (window.innerWidth <= 768 && !isMobile) {
    store.dispatch(setIsMobile(true))
  } else if (window.innerWidth > 768 && isMobile) {
    store.dispatch(setIsMobile(false))
  }
  if (
    window.innerWidth <= 850 &&
    window.innerWidth >= 374 &&
    window.devicePixelRatio >= 2 &&
    window.innerHeight < window.innerWidth &&
    !isLandscape
  ) {
    store.dispatch(setIsLandscape(true))
  } else if (
    window.innerWidth <= 850 &&
    window.innerWidth >= 374 &&
    window.innerHeight >= window.innerWidth &&
    isLandscape
  ) {
    store.dispatch(setIsLandscape(false))
  }
}
