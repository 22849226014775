import React from 'react'
import { Link } from 'gatsby'

import './article-preview.sass'

export default ({ article }) => (
  <div className="preview">
    <Link to={`/blog/${article.slug}`}>
      <div>
        <div className="article-image">
          <img
            alt=""
            src={article.heroImage && article.heroImage.file && article.heroImage.file.url}
          />
        </div>
        <h3 className="preview-title">{article.title}</h3>
        <small>{article.publishDate}</small>
        {article && article.description && (
          <span
            className="description"
            dangerouslySetInnerHTML={{
              __html: article.description.childMarkdownRemark.html,
            }}
          />
        )}
        <div className="tags">
          {article.tags.map(tag => (
            <p className="tag" key={tag}>
              {tag}
            </p>
          ))}
        </div>
      </div>
    </Link>
  </div>
)
