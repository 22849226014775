import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import menuIcon from '@images/menu-icon.svg'
import closeIcon from '@images/close-icon.svg'
import './navigation.sass'

const navigationLinks = [
  {
    slug: '/',
    label: 'Home',
  },
  {
    slug: '/about',
    label: 'About',
  },
  {
    slug: '/contact',
    label: 'Contact',
  },
  {
    slug: '/blog',
    label: 'Blog',
  },
]

export default ({ isMobile }) => {
  const [navIsActive, setNavIsActive] = React.useState(false)
  !isMobile && navIsActive && setNavIsActive(false)
  return (
    <nav role="navigation" className="navigation">
      {!isMobile && (
        <ul className="desktop-navigation">
          {navigationLinks.map(navLink => (
            <li key={navLink.label} className="navigation-item">
              <Link to={navLink.slug}>
                <span>{navLink.label}</span>
                <span className="slider"></span>
              </Link>
            </li>
          ))}
        </ul>
      )}
      {isMobile && (
        <>
          <button
            type="button"
            tabIndex="0"
            value="Mobile Menu"
            aria-label="Mobile Menu"
            aria-expanded={navIsActive}
            onClick={() => setNavIsActive(!navIsActive)}
          >
            <img
              className={classNames('icon', { fadeIn: !navIsActive, fadeOut: navIsActive })}
              alt="navigation menu open"
              src={menuIcon}
            />
            <img
              className={classNames('icon close', { fadeIn: navIsActive, fadeOut: !navIsActive })}
              alt="navigation menu close"
              src={closeIcon}
            />
          </button>
          <ul
            className={classNames('mobile-navigation', {
              fadeIn: navIsActive,
              fadeOut: !navIsActive,
            })}
          >
            {navigationLinks.map(navLink => (
              <li key={navLink.label} className="navigation-item">
                <Link to={navLink.slug}>
                  <span>{navLink.label}</span>
                  <span className="slider"></span>
                </Link>
              </li>
            ))}
          </ul>
        </>
      )}
    </nav>
  )
}
