import React from 'react'
import Img from 'gatsby-image'

import './hero.sass'

export default ({ data }) => (
  <div className="hero">
    {data.heroImage && data.heroImage.fluid && (
      <Img className="heroImage" alt={data.name} fluid={data.heroImage.fluid} />
    )}
    <div className="heroDetails">
      <h2 className="heroHeadline">{data.name}</h2>
      <p className="heroTitle">{data.title}</p>
      <p>{typeof data.shortBio === 'string' ? data.shortBio : data.shortBio.shortBio}</p>
    </div>
  </div>
)
