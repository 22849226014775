import React from 'react'
import classNames from 'classnames'
import Helmet from 'react-helmet'
import Header from '../header'
import Footer from '../footer'
import '@sass/index.sass'

export default ({ className, children }) => (
  <>
    <Helmet htmlAttributes={{ lang: 'en' }} />
    <Header />
    <div className={classNames('container', className)} role="main">
      {children}
    </div>
    <Footer />
  </>
)
